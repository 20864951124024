import React from "react";

import HTMLReactParser from "html-react-parser";

import { Wrapper } from "./TextBlock.styles";

const TextBlock = (props) => {
  //PROPS
  const { textBlock } = props;
  //PROPS

  return (
    <Wrapper className="container container--small">
      {textBlock && HTMLReactParser(textBlock)}
    </Wrapper>
  );
};

export default TextBlock;
