import styled from "styled-components";
import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  margin-bottom: ${toRem(30)};

  video {
    max-height: 500px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
  }
`;
