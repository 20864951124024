import React, { useEffect, useRef, useState } from "react";

import { Wrapper } from "./Video.styles";
import ReactPlayer from "react-player/file";

const Video = (props) => {
  //PROPS
  const {
    video: { mediaItemUrl },
  } = props;
  //PROPS

  //USESTATE
  const [video, setVideo] = useState({
    playing: true,
    controls: true,
    volume: 0.2,
    muted: true,
    duration: 0,
    autoplay: false,
    withSound: false,
    played: 0,
    ready: false,
  });
  //USESTATE

  //REF
  const thePlayer = useRef(null);
  //REF
  useEffect(() => {
    setVideo({
      ...video,
      playing: true,
    });
  }, []);

  return (
    <Wrapper className="container container--small">
      <ReactPlayer
        playsinline={true}
        url={mediaItemUrl}
        width="100%"
        height="100%"
        //
        onReady={() => setVideo({ ...video, ready: true })}
        onPause={() => setVideo({ ...video, playing: false })}
        //
        loop={true}
        controls={false}
        playing={video.playing}
        light={video.light}
        muted={video.muted}
        ref={thePlayer}
      />
    </Wrapper>
  );
};

export default Video;
