import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import { Wrapper } from "./Image.styles";

const Image = (props) => {
  //PROPS
  const { image, ismax } = props;
  //PROPS

  //INVIEW
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "100px 0px",
  });
  //INVIEW

  return (
    <Wrapper className="container container--small" ref={ref} isMax={ismax}>
      <GatsbyImage
        image={
          inView ? image?.localFile?.childImageSharp?.gatsbyImageData : null
        }
        alt={image.altText}
      />
    </Wrapper>
  );
};

export default Image;
