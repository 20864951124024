import styled from "styled-components";

import { toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${toRem(13)};

  @media (min-width: 769px) {
    margin-bottom: ${toRem(70)};
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    max-width: ${(props) => (props.isMax ? "600px" : "")};
  }
`;
