import React, { useEffect } from "react";

import { graphql } from "gatsby";
import gsap from "gsap";
import { motion } from "framer-motion";

import PostBanner from "components/postBanner";
import PostContent from "components/postContent";
import Footer from "components/footer";
import { BackButton } from "components/common/BackButton";

const containerVariants = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0, duration: 0 },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { ease: "easeInOut", duration: 1 },
  },
};

const Post = (props) => {
  const tl = gsap.timeline();

  //PROPS
  const {
    pageContext: { odd },
    data: {
      page: {
        title,
        work: { image, text, dynamicContent },
      },
    },
    setColor,
  } = props;
  //PROPS

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";
  //DEFS

  //USEEFFECT
  useEffect(() => {
    tl.to(".info", {
      duration: 0.4,
      css: {
        opacity: 1,
      },
    }).to(".content", {
      duration: 0.3,
      css: {
        opacity: 1,
      },
    });
  }, []);

  useEffect(() => {
    setColor(true);
  }, []);
  //USEEFFECT

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <PostBanner image={image} title={title} text={text} odd={odd} />
        <div className="content">
          <PostContent data={dynamicContent} />
        </div>
        <BackButton
          to={lang === "EN" ? "/en/what-we've-done" : "/o-que-fizemos"}
        >
          {lang === "EN" ? "Return" : "Voltar"}
        </BackButton>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query Post($id: String) {
    page: wpPost(id: { eq: $id }) {
      title
      work {
        dynamicContent {
          ... on WpPost_Work_DynamicContent_Image {
            fieldGroupName
            ismax
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 100
                    formats: NO_CHANGE
                  )
                }
              }
            }
          }
          ... on WpPost_Work_DynamicContent_Video {
            fieldGroupName
            video {
              mediaItemUrl
            }
          }
          ... on WpPost_Work_DynamicContent_TextBlock {
            textBlock
            fieldGroupName
          }
        }
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 1920
                quality: 100
                formats: NO_CHANGE
              )
            }
          }
        }
      }
    }
  }
`;

export default Post;
