import React from "react";

import Image from "./image";
import Video from "./video";
import TextBlock from "./textBlock";

const PostContent = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  if (!!data) {
    return data
      .filter((o) => o !== null)
      .map(({ fieldGroupName, ...rowData }, index) => {
        const type = fieldGroupName?.split("_").slice(-1)[0];

        const components = {
          Image,
          TextBlock,
          Video,
        };
        const Component = components[type];
        return Component && <Component {...rowData} key={index} />;
      });
  } else {
    return null;
  }
};

export default PostContent;
