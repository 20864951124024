import React from "react";

import HTMLReactParser from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

import { PostBannerWrapper } from "./PostBanner.styles";

const PostBanner = (props) => {
  const { text, title, image } = props;

  return (
    <PostBannerWrapper>
      <GatsbyImage
        image={image.localFile?.childImageSharp?.gatsbyImageData}
        alt={image.altText}
      />
      <div className="container info">
        <h1 className="title">{title}</h1>
        {text && HTMLReactParser(text)}
      </div>
    </PostBannerWrapper>
  );
};

export default PostBanner;
